







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ReactionFace extends Vue {
  @Prop()
  value!: string
  private FACE = {
    1: {
      className: 'veryGood',
      text: 'とてもよくできた！'
    },
    2: {
      className: 'good',
      text: 'できた！'
    },
    3: {
      className: 'soso',
      text: '少し不安'
    },
    4: {
      className: 'bad',
      text: 'できなかった'
    },
    5: {
      className: 'errorSystem',
      text: '不具合を見つけた'
    },
    6: {
      className: 'askTeacher',
      text: '先生に質問したい'
    },
  }
  private get reactFace() {
    return this.FACE[+this.value || 1]
  }
}
