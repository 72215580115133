import { Component, Mixins, Ref, Vue } from 'vue-property-decorator'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import { set } from 'lodash'

export type Condition = {
  label: string
  name: string
  key: string
  value: string
}

@Component
export default class Timelines extends Mixins(ClassModeChangeAble) {
  @Ref() observeElement!: HTMLElement

  protected keyCondition = ''
  protected title = 'タイムライン・メモ・質問履歴'
  protected observer!: IntersectionObserver
  protected isMenuOpened = false
  protected conditions: Condition[] = []
  protected timelineData: any[] = []
  protected lastData: any = {}
  protected tabBaseDatas: { link: string; name: string }[] = [
    {
      link: '/student/v3/timeline',
      name: 'タイムライン',
    },
    {
      link: '/student/v3/report',
      name: 'メモ',
    },
    {
      link: '/student/v3/history-chat',
      name: '質問履歴',
    },
  ]
  protected optionsObserve: IntersectionObserverInit = { rootMargin: '200px 0px' }

  protected async setTimelineCookie() {
    // クッキーに検索条件をセット
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie[this.keyCondition] = this.conditions
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  protected menuOpen(): void {
    this.isMenuOpened = true
  }

  protected menuClose(): void {
    this.isMenuOpened = false
  }

  protected async init(loadDatas: () => void, restoreConditions?: any, select?: any) {
    await this.checkClassModeAndSideMenuMode()
    this.observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry && entry.isIntersecting) {
        // 追加取得
        loadDatas()
      }
    }, this.optionsObserve)
    this.observer.observe(this.observeElement)
    // クッキーに検索条件が存在する場合はセットする
    if (!this.keyCondition) {
      return
    }
    const conditions = Vue.prototype.$cookies.get('dataGdls')[this.keyCondition]
    if (conditions) {
      restoreConditions?.(conditions)
    } else {
      select?.()
    }
  }
}
