

































import { Component, Vue, Ref, Watch, Mixins } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import StudentInformationReport from '@/components/organisms/v3/StudentInfomationReport.vue'
import CheckboxWithLabel from '@/components/molecules/CheckboxWithLabel.vue'
import RangeDatePicker from '@/components/molecules/RangeDatePicker.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import Conditions from '@/components/organisms/Conditions.vue'
import moment from 'moment'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import TabBase from '@/components/atoms/v3/TabBase.vue'
import { ReportType } from '@/types/teacher/report'
import FaceCheckbox from '@/components/molecules/v3/FaceCheckbox.vue'
import Timelines from '@/mixins/v3/Timelines'
import { Condition } from '@/mixins/v3/Timelines'

const LIMIT = 10

@Component({
  components: {
    SidebarSwitcher,
    CheckboxWithLabel,
    RangeDatePicker,
    ButtonBase,
    Conditions,
    StudentInformationReport,
    TabBase,
    FaceCheckbox,
  },
})
export default class Report extends Mixins(LocalMoment, ClassModeChangeAble, Timelines) {
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private page = 1

  private faceCheckbox = [
    { value: 1, label: 'とてもよくできた！' },
    { value: 2, label: 'できた！' },
    { value: 3, label: '少し不安' },
    { value: 4, label: 'できなかった' },
    { value: 5, label: '不具合を見つけた' },
    { value: 6, label: '先生に質問したい' },
  ]

  @Ref() rangeDatePicker!: RangeDatePicker

  private handleChangeFace(faces: any[]) {
    this.faceCheckbox = [...faces]
  }

  private created() {
    this.keyCondition = 'reportConditions'
  }

  private async mounted(): Promise<void> {
    await this.init(this.loadDatas, this.restoreConditions, this.select)
  }

  private async loadDatas() {
    const params = this.conditions.reduce((accumlator: any, condition: any): any => {
      accumlator[condition.key] = condition.value
      return accumlator
    }, {})
    params.limit = LIMIT
    params.page = this.page++
    params.user_id = this.userId
    params['branch_id'] = this.branchId

    // TODO: 生徒アイコンは未定、無限スクロール対応は後回し
    await Vue.prototype.$http.httpWithToken
      .get('/v3/user_note_reports', {
        params: params,
      })
      .then((res: any) => {
        const add_reports: ReportType[] = res.data.note_reports.map((report: ReportType) => {
          return {
            id: report.id,
            note_report_title: report.note_report_title,
            created_at: report.created_at,
            note_report_comment: report.note_report_comment,
            note_report_result: report.note_report_result,
            note_report_type: report.note_report_type,
            note_report_url: report.note_report_url,
            updated_at: report.updated_at,
            user_id: report.user_id,
            grade_name: report.grade_name,
            nickname: report.nickname,
            school_name: report.school_name,
            student_code: report.student_code,
          } as ReportType
        })
        this.timelineData.push(...add_reports)
        this.lastData = res.data.meta.lastData
        // 最終行の場合、監視終了
        if (!add_reports.length) this.observer.disconnect()
      })
  }

  private clear() {
    this.rangeDatePicker.startDatePickerBase.selectedDate = this.beforeDays(14).toDate()
    this.rangeDatePicker.endDatePickerBase.selectedDate = null
    this.faceCheckbox = this.faceCheckbox.map((face: any) => {
      return { ...face, checked: false }
    })
  }

  private select() {
    const conditionsDatas = []

    // 期間開始
    const startDate = this.rangeDatePicker.startDatePickerBase.selectedDate
    if (startDate) {
      conditionsDatas.push({
        label: '期間開始',
        name: moment(startDate).format('YYYY/MM/DD'),
        key: 'startedAtGteq',
        value: moment(startDate).startOf('day').format('YYYY/MM/DD HH:mm:ssZ'),
      })
    }

    // 期間終了
    const endDate = this.rangeDatePicker.endDatePickerBase.selectedDate
    if (endDate) {
      conditionsDatas.push({
        label: '期間終了',
        name: moment(endDate).format('YYYY/MM/DD'),
        key: 'startedAtLteq',
        value: moment(endDate).endOf('day').format('YYYY/MM/DD HH:mm:ssZ'),
      })
    }

    // メモ・不具合種分
    this.addCheckBoxConditionData(conditionsDatas, this.faceCheckbox, 'メモ・不具合種分', 'note_report_result')

    this.conditions = conditionsDatas
    this.setTimelineCookie()
    this.menuClose()
  }

  private addCheckBoxConditionData(conditionsDatas: any, checkBoxDatas: any, label: string, key: string) {
    const selectedDatas = checkBoxDatas.filter((data: any) => data.checked)
    if (selectedDatas.length > 0) {
      conditionsDatas.push({
        label: label,
        name: selectedDatas.map((data: any) => data.label).join('、'),
        key: key,
        value: selectedDatas.map((data: any) => data.value),
      })
    }
  }

  private removeConditions(label: string) {
    if (label === '期間開始') this.rangeDatePicker.startDatePickerBase.selectedDate = null
    if (label === '期間終了') this.rangeDatePicker.endDatePickerBase.selectedDate = null
    if (label === 'メモ・不具合種分') {
      this.faceCheckbox = this.faceCheckbox.map((face: any) => {
        return { ...face, checked: false }
      })
    }

    this.conditions = this.conditions.filter((condition: { label: string }) => condition.label !== label)
    this.setTimelineCookie()
  }

  private restoreConditions(conditions: Condition[]) {
    this.conditions = conditions
    const start = conditions.find((condition: { label: string }) => condition.label === '期間開始')
    if (start) {
      this.rangeDatePicker.startDatePickerBase.selectedDate = new Date(start.value)
    } else {
      this.rangeDatePicker.startDatePickerBase.selectedDate = null
    }
    const end = conditions.find((condition: { label: string }) => condition.label === '期間終了')
    if (end) {
      this.rangeDatePicker.endDatePickerBase.selectedDate = new Date(end.value)
    }
    const faceCheckbox = conditions.find((condition: { label: string }) => condition.label === 'メモ・不具合種分')
    if (faceCheckbox) {
      this.faceCheckbox = this.faceCheckbox.map((data: any) => {
        return {
          ...data,
          checked: faceCheckbox.value.includes(data.value),
        }
      })
    }
  }

  @Watch('conditions', { deep: true })
  private conditionsChange() {
    // 条件が設定された時の初回取得
    this.page = 1
    this.timelineData = []
    this.lastData = {}
    this.loadDatas()
    this.observer.observe(this.observeElement)
  }
}
