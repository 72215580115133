









































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { isMobile } from '@/utils/teacher/indext'

@Component
export default class FaceCheckbox extends Vue {
  @Prop({ default: false })
  disabled?: boolean

  @Prop()
  options!: []

  @Prop()
  onChange!: (faces: any[]) => void

  @Prop()
  size!: 'small' | 'medium' | 'large'

  @Prop({ default: 'radio' })
  type!: 'checkbox' | 'radio'

  private isPc = true
  private handleInput(e: any, index: number) {
    const checked = e.target.checked
    const _options: any[] = [...this.options]
    _options[index].checked = checked
    if (this.type === 'radio') {
      _options.forEach((option, _index) => {
        if (index != _index) {
          option.checked = false
        }
      })
    }
    this.onChange(_options)
  }

  private mounted() {
    this.isPc = !isMobile()
  }
}
