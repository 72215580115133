







































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ImageRound from '@/components/atoms/ImageRound.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import ReactionFace from '@/components/atoms/v3/ReactionFace.vue'
import { ReportType } from '@/types/teacher/report'
import moment from 'moment'
import DrillWebApi from '@/mixins/drillsv3/WebApi'
import { PREFIX_URL_NOT_PAGE_STUDY } from '@/constants'

@Component({
  components: {
    ColoredBox,
    ImageRound,
    LabelBase,
    ReactionFace,
  },
})
export default class StudentInformationReport extends Mixins(DrillWebApi) {
  @Prop({ default: {} })
  activity!: ReportType

  @Prop({ default: true })
  isTeacher?: boolean

  private isDeleted = false

  private role = Vue.prototype.$cookies.get('authGdls').role

  private get isReportOfPageStudy() {
    const subPath = this.activity.note_report_url?.substring(0, PREFIX_URL_NOT_PAGE_STUDY.length)
    return !(subPath === PREFIX_URL_NOT_PAGE_STUDY)
  }
  private get isStudent() {
    return { 'is-student': !this.isTeacher }
  }
  private get showDelete() {
    return (!this.isTeacher || this.activity.note_report_result == 5) && ['branch', 'student'].includes(this.role)
  }
  private get createdDate() {
    return moment(this.activity.created_at).format('YYYY/MM/DD')
  }
  private get createdTime() {
    return moment(this.activity.created_at).format('HH:mm')
  }

  private confirmDelete() {
    const result = confirm(`削除しますか？`)
    if (result == true) {
      this.deleteReport()
    }
  }

  private pushLink(url: string) {
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.click()
    link.remove()
  }

  private async openLink() {
    const uri = `/report${this.activity.note_report_url}&title=${encodeURIComponent(
      this.activity.note_report_title || ''
    )}&type=${this.activity.note_report_result}&content=${encodeURIComponent(
      this.activity.note_report_comment || ''
    )}&isBug=${this.activity.note_report_type == 2}`

    if (this.isTeacher) {
      this.pushLink(`/teacher${uri}&prevPage=ReportV3`)
    } else {
      this.pushLink(`/student${uri}&prevPage=StudentReportV3`)
    }
  }

  private async deleteReport() {
    const res = await Vue.prototype.$http.httpWithToken.delete(`/v3/user_note_reports/${this.activity.id}`)
    if (res.status == 204) {
      this.isDeleted = true
    } else {
      alert('システムエラーが発生しましたので、後でもう一度お試しください。')
    }
  }
}
